// Image Compression parameters
export const COMPRESS_PARAMS_EXTRA_EXTRA_LARGE =
  "?auto=format%2Ccompress&format=webp&w=800&dpr=2.0";
export const COMPRESS_PARAMS_EXTRA_LARGE =
  "?auto=format%2Ccompress&format=webp&w=600&dpr=2.0";
export const COMPRESS_PARAMS_LARGE =
  "?auto=format%2Ccompress&format=webp&w=480&dpr=2.0";
export const COMPRESS_PARAMS_MEDIUM =
  "?auto=format%2Ccompress&format=webp&w=400&dpr=2.0";
export const COMPRESS_PARAMS_SMALL =
  "?auto=format%2Ccompress&format=webp&w=240&dpr=2.0";
export const COMPRESS_PARAMS_EXTRA_SMALL =
  "?auto=format%2Ccompress&format=webp&w=200&dpr=2.0";

// Images
export const ENGLISH_TRANSPARENT_LOGO_URL =
  "https://images.assettype.com/newslaundry/2025-01-17/1s6f7jr0/englishtransparentlogo.png";
export const DARK_ENGLISH_TRANSPARENT_LOGO_URL =
  "https://images.assettype.com/newslaundry/2025-01-17/rroze3cq/darkenglishtransparentlogo.png";
export const HINDI_TRANSPARENT_LOGO_URL =
  "https://images.assettype.com/newslaundry/2025-01-17/z7l2pco6/hinditransparentlogo.png";
export const DARK_HINDI_TRANSPARENT_LOGO_URL =
  "https://images.assettype.com/newslaundry/2025-01-17/yf7e43bm/darkhinditransparentlogo.png";
export const NEWSLAUNDRY_SQUARE_LOGO =
  "https://images.assettype.com/newslaundry/2025-01-23/v1nrf2m4/newslaundrylogo.png";
export const BIRUBAL_PEN_NOTEPAD_SUBSCRIPTION_APPEAL =
  "https://images.assettype.com/newslaundry/2025-01-23/wk8tqh9m/subsccription-banner-on-homepage.png";
export const BIRUBAL_NEWSLETTERS =
  "https://images.assettype.com/newslaundry/2023-02/e68aecc2-4308-4d66-9802-4b047ec3ed46/newsletter.png";
export const BIRUBAL_READING_NEWS_PODCAST =
  "https://images.assettype.com/newslaundry/2025-01-23/swl3p8aw/nlpodcast.png";
export const BIRUBAL_READING_NEWS_PODCAST_INVERTED =
  "https://images.assettype.com/newslaundry/2025-01-24/tinlyf3u/podcast-collection-new.png";
export const BIRUBAL_STORE_INAUGRATION_STORE =
  "https://images.assettype.com/newslaundry/2023-11/039fe5c3-f57d-41d4-bdc4-d17064248194/NL_store_1_copy.png";
export const BIRUBAL_BIRUBAL_WEARING_NL_STORE_MERCH =
  "https://images.assettype.com/newslaundry/2024-01/7ddbf3c9-dd6a-4c15-b436-4503090b104d/nl_store_main.png";
export const SUBSCRIBER_ONLY_MEET_UPS =
  "https://images.assettype.com/newslaundry/2025-01-24/ss2zmwj9/meetups.jpg";
export const SUBSCRIBER_ONLY_EVENTS =
  "https://images.assettype.com/newslaundry/2025-01-24/alhiz3u0/events.jpeg";
export const SUBSCRIBER_ONLY_FILM_SCREENINGS =
  "https://images.assettype.com/newslaundry/2025-01-24/h40dzm53/film.jpg";
export const HANDS_HOLDING_NL_CLIP =
  "https://images.assettype.com/newslaundry/2022-05/20483425-b415-4739-9a75-ea5314011dc9/NL_Subscription_2.png";
export const PEOPLE_HOLDING_NL_CLIP =
  "https://images.assettype.com/newslaundry/2022-08/fb9ec400-b15f-4aee-8c58-0a15d19d147b/NL_HOMEPAGE_1_copy.png";
export const BIRUBAL_BIRUBALA_SAD_TO_SEE_YOU_GO =
  "https://images.assettype.com/newslaundry/2022-11/f569bb2b-90f6-469b-9724-bc6a31502307/1920x1080__2_.png";
export const BIRUBALA_SUBSCRIBER_BALOONS =
  "https://images.assettype.com/newslaundry/2022-11/a619e8f0-3de9-4667-9113-ee966aada366/1284x2058.png";
export const BIRUBAL_BIRUBALA_NL_SENA =
  "https://images.assettype.com/newslaundry/2022-06/afd6c5e0-3fc7-4daf-812f-84cf6e733a11/nlsenapng.png";
export const BIRUBAL_BIRUBALA_LAWYERS_LEGAL_FUND =
  "https://images.assettype.com/newslaundry/2022-05/17aa4480-74b6-494e-b380-c853c3c4dffb/nl_legal_fund.png";
export const AWARDS_NL_CLIP_IN_LIGHTS =
  "https://images.assettype.com/newslaundry/2022-06/cc953b64-c1ce-47a4-be4b-159ba8ee5243/NL_awards.png";
export const TEAM_AVATARS =
  "https://images.assettype.com/newslaundry/2022-05/cf58edd1-9ecf-47f6-b735-fdd3c5e3fdc2/NL_Subscription3.png";
export const VIDEO_FILM_ROLL =
  "https://images.assettype.com/newslaundry/2022-05/b59d9bdb-bebe-45a8-986c-eba6927a2689/NL_Videos_copy.png";
export const BIRUBALA_STUDENT =
  "https://images.assettype.com/newslaundry/2022-05/a2ef3fe0-5b4f-4913-a146-0724fa8b4409/NL_Subscription_8.png";
export const BIRUBAL_BIRUBALA_UNVEILING_NL_APP =
  "https://images.assettype.com/newslaundry/2022-07/f878db66-9de4-4485-b0f8-e79a7107f91d/Website_AI_new2.jpeg";
export const BIRUBAL_REFUSING_BRIBE_SAY_NO_TO_MONEY =
  "https://images.assettype.com/newslaundry/2022-05/37d862f1-d823-4244-8056-b3c9bc84759e/NL_Subscription_1_copy.png";
export const BIRUBALA_GIFTING =
  "https://images.assettype.com/newslaundry/2022-05/fdbd5b18-e3b2-4414-87f9-08c15e1b6d82/NL_Subscription_4_copy.png";
export const BIRUBAL_AD_FREE_SAY_NO_TO_ADS =
  "https://images.assettype.com/newslaundry/2022-05/7f7922fa-a1a3-474e-9349-fe65ac7b25f7/NL_Subscription_5.png";
export const TRAFFIC_POLICE_BIRUBAL_STOP_NUDGE =
  "https://images.assettype.com/newslaundry/2024-10-25/arxf71wz/nudges21.png";
export const BIRUBALA_JOURNALIST_ASKING_QUESTIONS =
  "https://images.assettype.com/newslaundry/2022-05/7fc22087-a2a3-496b-a5ac-cdf3fb27e26e/NL_Subscription_6_copy.png";
export const STUDENT_SUBSCRIPTION =
  "https://images.assettype.com/newslaundry/2022-03/7b8c9113-5364-48aa-a12b-7581be1310d7/student_subscription_main.png";
export const BIRUBAL_GIFTING_NL_CLIP_BOX_SUBSCRIPTION =
  "https://images.assettype.com/newslaundry/2022-05/55f11899-d274-4bd7-884f-8a304578313a/NL_Subscription_7.png";
export const BIRUBAL_AND_BIRUBALA_ON_VIDEO_TV =
  "https://images.assettype.com/newslaundry/2025-01-24/sr31svb0/videos-collection-page.png";
export const PEOPLE_CARRYING_NL_CLIP_UPHILL =
  "https://images.assettype.com/newslaundry/2022-08/fb9ec400-b15f-4aee-8c58-0a15d19d147b/NL_HOMEPAGE_1_copy.png";
export const BIRUBAL_NO_COMMENTS =
  "https://images.assettype.com/newslaundry/2025-02-07/9orbrtzj/no-comments-new.png";
export const BIRUBAL_BIRUBALA_ANNOUNCEMENT =
  "https://images.assettype.com/newslaundry/2022-06/7b3254cc-62ff-4135-9ea6-5c43169e87b1/contact_us_page.png";

// Podcast show covers
export const PODCAST_SHOW_HAFTA =
  "https://images.assettype.com/newslaundry/2025-01-27/7ndmt7so/HAFTA.jpg";
export const PODCAST_SHOW_LTA =
  "https://images.assettype.com/newslaundry/2025-01-27/u7mra89e/LTA.jpeg";
export const PODCAST_SHOW_YBTH =
  "https://images.assettype.com/newslaundry/2025-01-27/vosm57rt/YBTH.jpeg";
export const PODCAST_SHOW_ANA =
  "https://images.assettype.com/newslaundry/2025-01-27/xqlo1lbo/ANA.jpeg";
export const PODCAST_SHOW_CHARCHA =
  "https://images.assettype.com/newslaundry/2025-01-27/vo78piea/CHARCHA.jpeg";
export const PODCAST_SHOW_NL_INTERVIEWS =
  "https://images.assettype.com/newslaundry/2025-01-27/u1lrjffq/NLINTERVIEWS.jpeg";
