import React from "react";
import PT from "prop-types";
import get from "lodash/get";
import { connect } from "react-redux";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import { ACCOUNT_DETAILS_URL } from "../../../constants";
// import { getFirebaseUser } from "../../helper/api";
import "./user-data.m.css";

import { FaUser } from "react-icons/fa6";
import { Button } from "../../../ui/components";
// import { Link } from "@quintype/components";

class UserDataBaseHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserData: false
    };
    this.myElementRef = React.createRef();
  }

  focusOnElement = () => {
    if (this.myElementRef.current) {
      // console.log(this.myElementRef.current)
      this.myElementRef.current.focus();
      // console.log("iiiiiiiiiii", this.myElementRef.current);
    }
  };

  // componentDidMount() {
  //   this.props.initAccessType(userSubscription);
  // }

  logoutHandler = event => {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    this.props
      .logout()
      .then(() => {
        this.props.onBackdropClick(false);
        this.props.manageLoginForm(false);
        this.setState({ showUserData: !this.state.showUserData });
        // eslint-disable-next-line no-undef
        localStorage.setItem("one-tap-display", true);
      })
      .catch(error => {
        console.warn("Logout failed", error);
        return null;
      });
  };

  openMenu = () => {
    this.setState({ showUserData: !this.state.showUserData });
    // this.focusOnElement();
    // if (this.myElementRef.current && this.state.showUserData ) {
    //   // console.log(this.myElementRef.current)
    //   this.myElementRef.current.focus();
    //   console.log("iiiiiiiiiii", this.myElementRef.current);
    // }
  };

  componentDidUpdate() {
    if (this.state.showUserData) {
      if (this.myElementRef.current) {
        // console.log(this.myElementRef.current)
        this.myElementRef.current.focus();
        // console.log("iiiiiiiiiii", this.myElementRef.current);
      }
    }
  }

  closeMenu = () => {
    this.setState({ showUserData: false });
  };

  handleKeyDown = event => {
    // event.persist();
    event.preventDefault();
    // event.stopPropagation();
    if (event.key === "Escape") {
      this.closeMenu();
    }
  };

  profileHandler = () => {
    this.setState({ showUserData: !this.state.showUserData });
    // eslint-disable-next-line no-undef
    // localStorage.setItem("one-tap-display", !this.state.showUserData);
    this.props.navigateTo(ACCOUNT_DETAILS_URL);
  };

  savedHandler = () => {
    this.props.navigateTo("/saved-stories");
  };

  render() {
    // const { avatarName } = this.props;
    // const { member = {}, publisherConfig, avatarName } = this.props;
    // const imageUrl = member["avatar-s3-key"] ? publisherConfig["cdn-image"] + member["avatar-s3-key"] : userIcon;
    // const imageUrl = `https://ui-avatars.com/api/?name=${avatarName}&background=transparent&color=fff&size=128&length=1`;

    return (
      //   <div styleName="wrapper">
      //   <button
      // styleName="user-btn"
      // onClick={this.openMenu}
      // aria-label="User account details"
      // aria-expanded={this.state.showUserData}
      // aria-controls="user-data-flyout-menu"
      // aria-haspopup="true"
      // onKeyDown={this.handleKeyDown}
      //   >
      //     <FaUser className="typography-paragraph-styles" aria-hidden="true" />
      //   </button>

      //   {
      //   this.state.showUserData && (
      //     <div
      //       id="user-data-flyout-menu"
      //       styleName="overlay"
      //       role="menu"
      //       // tabIndex="-1"
      //       // onBlur={this.closeMenu}
      //     >
      //       <ul styleName="lists">
      //         <li
      //           role="menuitem"
      //           aria-label="Click to go to your profile information"
      //           onClick={this.profileHandler}
      //           tabIndex="0"
      //         >
      //           My Profile
      //         </li>
      //         <li
      //           role="menuitem"
      //           aria-label="Click to view saved stories"
      //           onClick={this.savedHandler}
      //           tabIndex="0"
      //         >
      //           Saved Stories
      //         </li>
      //         <li
      //           role="menuitem"
      //           aria-label="Click to logout"
      //           onClick={this.logoutHandler}
      //           tabIndex="0"
      //         >
      //           Logout
      //         </li>
      //       </ul>
      //     </div>
      //   )}
      // </div>
      <div styleName="wrapper">
        <Button
          styleName="user-btn"
          onClick={this.openMenu}
          aria-label="User account details"
          aria-expanded={this.state.showUserData}
          aria-controls="user-data-flyout-menu"
          aria-haspopup="true"
          // onKeyDown={this.handleKeyDown}
          tabIndex="0"
        >
          <FaUser className="typography-paragraph-styles" aria-hidden="true" />
          {/* <img
            src={imageUrl}
            styleName="memberIcon"
            alt="Profile icon, click to view profile options"
          /> */}
        </Button>
        {/* <div
          styleName="user-details"
          onClick={() =>
            this.setState({ showUserData: !this.state.showUserData })
          }
          role="button" 
          aria-expanded={this.state.showUserData}>
          <img src={imageUrl} styleName="memberIcon" alt="Profile icon, click to view profile options" />
        </div> */}
        {this.state.showUserData && (
          <div
            id="user-data-flyout-menu"
            styleName="overlay"
            role="menu"
            tabIndex="0"
          >
            <ul styleName="lists">
              <li
                role="menuitem"
                aria-label="Click to go to your profile information"
                onClick={this.profileHandler}
                tabIndex="0"
              >
                <a
                  href="/account-details"
                  aria-hidden="true"
                  ref={this.myElementRef}
                >
                  My Profile
                </a>
              </li>
              <li
                role="menuitem"
                aria-label="Click to view saved stories"
                onClick={this.savedHandler}
                tabIndex="0"
              >
                Saved Stories
              </li>
              <li
                role="menuitem"
                aria-label="Click to logout"
                onClick={this.logoutHandler}
                tabIndex="0"
              >
                Logout
              </li>
            </ul>
          </div>
        )}
      </div>
    );
  }
}

UserDataBaseHandler.propTypes = {
  logout: PT.func,
  onBackdropClick: PT.func,
  member: PT.object,
  publisherConfig: PT.object,
  avatarName: PT.string,
  manageLoginForm: PT.func,
  navigateTo: PT.func,
  initAccessType: PT.func,
  getSubscriptionForUser: PT.func,
  userSubscriptionDetails: PT.func,
  userSubscriptionDetailsLoading: PT.func
};

const userDataBase = props => {
  // const [name, setName] = useState("+");
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const fireUser = await getFirebaseUser();
  //       setName(
  //         `${get(fireUser, ["data", "first_name"], "N") || "N"}+${get(
  //           fireUser,
  //           ["data", "last_name"],
  //           "L"
  //         ) || "L"}`
  //       );
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   })();
  // }, []);
  return <UserDataBaseHandler avatarName={"NL"} {...props} />;
};

userDataBase.propTypes = {
  publisherConfig: PT.object
};

const mapDispatchToProps = dispatch => ({
  manageLoginForm: function (payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload
    });
  },
  navigateTo: url => global.app.navigateToPage(dispatch, url)
});

const mapStateToProps = state => ({
  publisherConfig: get(state, ["qt", "config", "publisher"], {})
});

export const UserData = connect(
  mapStateToProps,
  mapDispatchToProps
)(userDataBase);
