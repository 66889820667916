/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from "react";
import { connect } from "react-redux";
import { SocialIcons } from "../../molecules/social-icons";
import get from "lodash/get";
import PT from "prop-types";
import "./footer.m.css";

function FooterBase(props) {
  const placeholders = props.menu.filter(
    item => item["item-type"] === "placeholder"
  );

  const pushToDataLayer = (event, title) => {
    const obj = {
      event: event,
      usertype: props.member ? "logged in" : "logged out",
      subscribertype: props.fetchSubscription
        ? props.fetchSubscription.isActive
          ? "subscriber"
          : "nonsubscriber"
        : "nonsubscriber",
      userID: props.member ? props.member.id : "null",
      subscriptionID: props.fetchSubscription
        ? props.fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (event === "bottom_navigation_click") {
      obj.bottom_navigation_cta = title;
    } else if (event === "nl_bottom_navigation") {
      if (title === "Subscribe") {
        obj.subscription_category = "self_subscription";
      }
      if (title === "Newsletters") {
        obj.subscription_category = "newsletter_subscription";
      }
      if (title === "Join NL Sena") {
        obj.subscription_category = "contribution_nl_sena";
      }
    }

    window.dataLayer.push(obj);
  };

  return (
    <footer styleName="footer">
      <div className="container">
        <div styleName="container">
          <ul styleName="menu-items">
            {placeholders.map(item => {
              return (
                <li key={item.id} styleName="menu-items-list">
                  <h3>{item.title}</h3>
                  <ul styleName="menu-items-list">
                    {item.children.map(child => (
                      <li
                        key={child.id}
                        onClick={() => {
                          if (
                            child.title === "Subscribe" ||
                            child.title === "Newsletters"
                          ) {
                            return pushToDataLayer(
                              "nl_bottom_navigation",
                              child.title
                            );
                          } else {
                            return pushToDataLayer(
                              "bottom_navigation_click",
                              child.title
                            );
                          }
                        }}
                      >
                        <a href={child.completeUrl}>{child.title}</a>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
          {!props.domainSlug ? (
            <div styleName="social-links">
              <h3>Follow</h3>
              <SocialIcons
                socialLinks={props.socialLinks}
                showPodcast
                showTelegram
                showLinkedin
                showSpotify
                showCastBox
                showRss
                showWhatsapp
              />
            </div>
          ) : (
            <div styleName="social-links">
              <p>हमसे जुड़ें</p>
              <SocialIcons
                socialLinks={props.socialLinks}
                // showDiscord
                showNewsletter
                showRSS
              />
            </div>
          )}
          <div styleName="last-div">
            {/* <div styleName="about">
              {!props.domainSlug ? (
                <h5>With your support, we can do more ground-reports</h5>
              ) : (
                <h5>
                  आपका सहयोग हमें ज्यादा से ज्यादा ग्राउंड रिपोर्ट करने की ताकत
                  देता है
                </h5>
              )}
              {!props.domainSlug ? (
                <a
                  styleName="nl-btn"
                  href="/sena?ref=website-footer"
                  onClick={() =>
                    pushToDataLayer("nl_bottom_navigation", "Join NL Sena")
                  }
                >
                  Join NL Sena
                </a>
              ) : (
                <a
                  styleName="nl-btn"
                  href={`/subscription?ref=footer-hindi`}
                  onClick={() =>
                    pushToDataLayer("nl_bottom_navigation", "Join NL Sena")
                  }
                >
                  सब्सक्राइब करें
                </a>
              )}
            </div> */}
            <div styleName="download-app-div">
              <h3>Download the Newslaundry app</h3>
              <div styleName="download-cta-div">
                <a
                  href="https://apps.apple.com/in/app/newslaundry/id1609227019?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    stroke="currentColor"
                    styleName="store-svg"
                  >
                    <title>App Store</title>
                    <path d="M8.8086 14.9194l6.1107-11.0368c.0837-.1513.1682-.302.2437-.4584.0685-.142.1267-.2854.1646-.4403.0803-.3259.0588-.6656-.066-.9767-.1238-.3095-.3417-.5678-.6201-.7355a1.4175 1.4175 0 0 0-.921-.1924c-.3207.043-.6135.1935-.8443.4288-.1094.1118-.1996.2361-.2832.369-.092.1463-.175.2979-.259.4492l-.3864.6979-.3865-.6979c-.0837-.1515-.1667-.303-.2587-.4492-.0837-.1329-.1739-.2572-.2835-.369-.2305-.2353-.5233-.3857-.844-.429a1.4181 1.4181 0 0 0-.921.1926c-.2784.1677-.4964.426-.6203.7355-.1246.311-.1461.6508-.066.9767.038.155.0962.2984.1648.4403.0753.1564.1598.307.2437.4584l1.248 2.2543-4.8625 8.7825H2.0295c-.1676 0-.3351-.0007-.5026.0092-.1522.009-.3004.0284-.448.0714-.3108.0906-.5822.2798-.7783.548-.195.2665-.3006.5929-.3006.9279 0 .3352.1057.6612.3006.9277.196.2683.4675.4575.7782.548.1477.043.296.0623.4481.0715.1675.01.335.009.5026.009h13.0974c.0171-.0357.059-.1294.1-.2697.415-1.4151-.6156-2.843-2.0347-2.843zM3.113 18.5418l-.7922 1.5008c-.0818.1553-.1644.31-.2384.4705-.067.1458-.124.293-.1611.452-.0785.3346-.0576.6834.0645 1.0029.1212.3175.3346.583.607.7549.2727.172.5891.2416.9013.1975.3139-.044.6005-.1986.8263-.4402.1072-.1148.1954-.2424.2772-.3787.0902-.1503.1714-.3059.2535-.4612L6 19.4636c-.0896-.149-.9473-1.4704-2.887-.9218m20.5861-3.0056a1.4707 1.4707 0 0 0-.779-.5407c-.1476-.0425-.2961-.0616-.4483-.0705-.1678-.0099-.3352-.0091-.503-.0091H18.648l-4.3891-7.817c-.6655.7005-.9632 1.485-1.0773 2.1976-.1655 1.0333.0367 2.0934.546 3.0004l5.2741 9.3933c.084.1494.167.299.2591.4435.0837.131.1739.2537.2836.364.231.2323.5238.3809.8449.4232.3192.0424.643-.0244.9217-.1899.2784-.1653.4968-.4204.621-.7257.1246-.3072.146-.6425.0658-.9641-.0381-.1529-.0962-.2945-.165-.4346-.0753-.1543-.1598-.303-.2438-.4524l-1.216-2.1662h1.596c.1677 0 .3351.0009.5029-.009.1522-.009.3007-.028.4483-.0705a1.4707 1.4707 0 0 0 .779-.5407A1.5386 1.5386 0 0 0 24 16.452a1.539 1.539 0 0 0-.3009-.9158Z" />
                  </svg>
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2022-05/13b3de80-f642-4a15-be70-874855d17ba4/512x512.png"
                    alt="app store button"
                  /> */}
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.newslaundry.android.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    role="img"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    stroke="currentColor"
                    styleName="store-svg"
                  >
                    <title>Google Play</title>
                    <path d="M22.018 13.298l-3.919 2.218-3.515-3.493 3.543-3.521 3.891 2.202a1.49 1.49 0 0 1 0 2.594zM1.337.924a1.486 1.486 0 0 0-.112.568v21.017c0 .217.045.419.124.6l11.155-11.087L1.337.924zm12.207 10.065l3.258-3.238L3.45.195a1.466 1.466 0 0 0-.946-.179l11.04 10.973zm0 2.067l-11 10.933c.298.036.612-.016.906-.183l13.324-7.54-3.23-3.21z" />
                  </svg>
                  {/* <img
                    src="https://images.assettype.com/newslaundry/2022-05/c4d901fe-bf33-4d3a-b720-58c2182a8e9c/512x512.png"
                    alt="google play store button"
                  /> */}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div styleName="copyright">
          <p styleName="copyright-text">
            © Newslaundry Media Private Limited. All Rights Reserved.
          </p>
          {/* <a href="https://www.quintype.com">
            <p styleName="copyright-text">Powered by Quintype</p>
          </a> */}
        </div>
      </div>
    </footer>
  );
}

FooterBase.propTypes = {
  menu: PT.arrayOf(
    PT.shape({
      completeUrl: PT.string,
      title: PT.string
    })
  ),
  socialLinks: PT.object,
  domainSlug: PT.string
};

function mapStateToProps(state) {
  const menu =
    get(state, ["qt", "data", "navigationMenu", "footerLinks"], []) ||
    get(state, ["qt", "data", "navigationMenu", "default"], []);
  return {
    menu: menu,
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    domainSlug: get(state, ["qt", "domainSlug"], null),
    frontEndUrl: get(state, ["qt", "config", "publisher", "frontend_url"], ""),
    member: get(state, ["member"], null),
    subscription: get(state, ["fetchSubscription"], {})
  };
}

export const Footer = connect(mapStateToProps)(FooterBase);
