/* eslint-disable react/prop-types */
import { Link } from "@quintype/components";
import PropTypes from "prop-types";
import React from "react";
// import get from "lodash/get";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { connect, useSelector } from "react-redux";
import { OffcanvasMenu } from "../../molecules/offcanvas-menu";
import { getGlobalPlayerRef } from "../../podcast-player/utils";
import "./navigation-links.m.css";
// import { isDesktopSizeViewport } from "../../../utils";

export class NavigationLinksBase extends React.Component {
  constructor() {
    super();
    this.state = {
      enabledSubmenuId: null,
      isMenuOpen: false
    };
    this.myElementRef = React.createRef();
  }

  componentDidMount() {
    const playerRef = getGlobalPlayerRef();
    this.removePlayerListener = playerRef.addPlayerListener(state => {
      this.setState({
        showPlayer: state.showMenu,
        playerState: state
      });
    });
  }

  toggleChildMenu(itemId) {
    if (this.state.enabledSubmenuId === itemId) {
      this.setState({
        enabledSubmenuId: null
      });
    } else {
      this.setState({
        enabledSubmenuId: itemId
      });
    }
  }

  closeChildMenu() {
    this.setState({
      enabledSubmenuId: null
    });
  }

  openMenu = () => {
    console.log("inside openMenu", this.state.isMenuOpen);
    this.setState({
      isMenuOpen: !this.state.isMenuOpen
    });
  };

  getClass(itemId) {
    if (itemId === this.state.enabledSubmenuId) {
      return "is-open";
    }
    return "";
  }

  componentDidUpdate() {
    if (this.state.isMenuOpen) {
      // console.log("hii");
      if (this.myElementRef.current) {
        // console.log("hii in");
        // console.log(this.myElementRef.current)
        this.myElementRef.current.focus();
        // console.log("iiiiiiiiiii", this.myElementRef.current);
      }
    }
  }

  pushToDataLayer(event, title) {
    const obj = {
      event: event,
      top_navigation_cta: title,
      usertype: this.props.state.member ? "logged in" : "logged out",
      subscribertype: this.props.state.fetchSubscription
        ? this.props.state.fetchSubscription.isActive
          ? "subscriber"
          : "nonsubscriber"
        : "nonsubscriber",
      userID: this.props.state.member ? this.props.state.member.id : "null",
      subscriptionID: this.props.state.fetchSubscription.isActive
        ? this.props.state.fetchSubscription.items.find(
            sub => sub.active === true
          ).id
        : "null"
    };

    window.dataLayer.push(obj);
  }

  render() {
    const menus = this.props.links.slice(0, 9);
    const restMenus = this.props.links.slice(9);
    const isHindi = global.location && global.location.host.includes("hindi");
    return (
      <OffcanvasMenu
        showOffcanvasMenu={this.state.showPlayer}
        closeMenu={this.props.closeMenu}
      >
        <ul styleName="navigation-links">
          {menus &&
            menus.map((item, index) => {
              if (item.children.length) {
                return (
                  <li styleName="item item--have-child" key={item.id}>
                    <div
                      styleName="link link--sm"
                      onClick={() => this.toggleChildMenu(item.id)}
                    >
                      {item.title}
                      <div className="navigation-links__link-icon">
                        {item.id === this.state.enabledSubmenuId ? (
                          <FaChevronUp />
                        ) : (
                          <FaChevronDown />
                        )}
                      </div>
                    </div>
                    <Link
                      href={item.completeUrl}
                      styleName="link--lg"
                      onMouseDown={() =>
                        this.pushToDataLayer("top_navigation_click", item.title)
                      }
                    >
                      {item.title}
                    </Link>
                    <ul
                      styleName={`navigation-submenu ${this.getClass(item.id)}`}
                    >
                      {item.children.map(child => {
                        return <SubmenuItem item={child} key={child.url} />;
                      })}
                    </ul>
                  </li>
                );
              }

              return (
                <li key={index} styleName="item">
                  {item.title === "idp page" ? (
                    <Link
                      styleName="link-idp"
                      href={item.completeUrl}
                      target={
                        item.title === "FoE Con 2023" ? "_blank" : "_self"
                      }
                      onMouseDown={() =>
                        this.pushToDataLayer("top_navigation_click", item.title)
                      }
                    >
                      {/* <span>🇮🇳</span> */}
                      <span>NEW</span>
                    </Link>
                  ) : (
                    <Link
                      style={{ display: "block" }}
                      styleName={
                        item.title === "Electoral Bonds"
                          ? "link-style-2"
                          : "link-style"
                      }
                      href={item.completeUrl}
                      target={
                        item.title === "FoE Con 2023" ? "_blank" : "_self"
                      }
                      onMouseDown={() =>
                        this.pushToDataLayer("top_navigation_click", item.title)
                      }
                    >
                      {item.title}
                      {/* {item.title === "Diwali Offer" && (
                        <small aria-hidden="true" styleName="new-banner">
                          NEW
                        </small>
                      )} */}
                    </Link>
                  )}
                </li>
              );
            })}
          {this.props.links && this.props.links.length > 9 ? (
            <li
              styleName="item item--have-child display-desktop-only"
              aria-label="click to display more options"
              tabIndex="0"
              onClick={this.openMenu}
            >
              <Link style={{ display: "block" }} styleName="link-style">
                <span>{isHindi ? "और पढ़ें" : "More"}</span>
              </Link>
              {/* {this.state.isMenuOpen && (
                <ul styleName={`navigation-submenu-2`}>
                  {this.props.links &&
                    this.props.links.slice(9).map((item, index) => {
                      return (
                        <SubmenuItem
                          item={item}
                          key={item.completeUrl}
                          menuRef={this.myElementRef}
                          openMenu={this.openMenu}
                        />
                      );
                    })}
                </ul>
              )} */}
              <ul styleName={`navigation-submenu`}>
                {this.props.links &&
                  this.props.links.slice(9).map((item, index) => {
                    return <SubmenuItem item={item} key={item.completeUrl} />;
                  })}
              </ul>
            </li>
          ) : null}

          {this.props.links && this.props.links.length > 9
            ? restMenus.map((item, index) => {
                return (
                  <li key={index} styleName="item display-mobile-only">
                    <Link
                      styleName="link-style"
                      href={item.completeUrl}
                      onMouseDown={() =>
                        this.pushToDataLayer("top_navigation_click", item.title)
                      }
                    >
                      {item.title}
                    </Link>
                  </li>
                );
              })
            : null}
        </ul>
      </OffcanvasMenu>
    );
  }
}

NavigationLinksBase.propTypes = {
  links: PropTypes.array,
  closeOffcanvasMenu: PropTypes.func,
  showOffcanvasMenu: PropTypes.bool,
  closeMenu: PropTypes.func,
  domainSlug: PropTypes.string
};

const SubmenuItem = props => {
  const { member, fetchSubscription } = useSelector(state => state);

  const pushToDataLayer = (event, title) => {
    const obj = {
      event: event,
      usertype: member ? "logged in" : "logged out",
      subscribertype: fetchSubscription.isActive
        ? "subscriber"
        : "nonsubscriber",
      userID: member ? member.id : "null",
      subscriptionID: fetchSubscription.isActive
        ? fetchSubscription.items.find(sub => sub.active === true).id
        : "null"
    };

    if (title === "Newsletter") {
      obj.subscription_category = "newsletter_subscription";
    } else {
      obj.top_navigation_cta = title;
    }

    window.dataLayer.push(obj);
  };

  return (
    <li styleName="submenu-item" tabIndex="0">
      <Link
        styleName="link-desktop-style"
        href={
          props.item["parent-slug"]
            ? props.item["parent-slug"] + props.item.completeUrl
            : props.item.completeUrl
        }
        onMouseDown={() => {
          if (props.item.title === "Newsletter") {
            pushToDataLayer("nl_top_navigation", props.item.title);
            return;
          }

          pushToDataLayer("top_navigation_click", props.item.title);
        }}
        // onClick={() => {
        //   props.openMenu;
        // }}
        ref={props.menuRef}
      >
        {props.item.title}
      </Link>
    </li>
  );
};

SubmenuItem.propTypes = {
  item: PropTypes.shape({
    "parent-slug": PropTypes.string,
    completeUrl: PropTypes.string,
    title: PropTypes.string
    // openMenu: PropTypes.func
  })
};

function mapStateToProps(state) {
  return { state };
}

export const NavigationLinks = connect(mapStateToProps)(NavigationLinksBase);
