/* eslint-disable dot-notation */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PT from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";

import { FaDiscord } from "react-icons/fa";
import "./social-icons.m.css";

import {
  FaFacebookF,
  FaGooglePlusG,
  FaXTwitter,
  FaLinkedin,
  FaYoutube,
  FaInstagram,
  FaRss,
  FaTelegram,
  FaSpotify,
  FaWhatsapp,
} from "react-icons/fa6";
import { SiCastbox, SiApplepodcasts } from "react-icons/si";
import { TbMailOpened } from "react-icons/tb";

export function SocialIconsBase({
  socialLinks = {},
  showPodcast = false,
  showTelegram = false,
  showLinkedin = false,
  showSpotify = false,
  showCastBox = false,
  showDiscord = false,
  showNewsletter = false,
  showRss = false,
  showWhatsapp = false,
  publisher,
}) {
  const [locations, setLocation] = useState("");
  useEffect(() => {
    setLocation(global.location.host);
  }, [locations]);
  const hindi_domain =
    locations === "newslaundry-hindi-web.qtstage.io" ||
    locations === "hindi.newslaundry.com" ||
    locations === "hindi.lvh.me:3000" ||
    locations === "hindi.localhost:3000";
  return (
    <ul styleName="social-icons" className="social-icons">
      {socialLinks["facebook-url"] && (
        <li styleName="social-icon">
          <a
            href={
              !hindi_domain
                ? socialLinks["facebook-url"]
                : // eslint-disable-next-line dot-notation
                  publisher["hiFacebook"]
            }
            target="_blank"
            rel="noopener noreferrer"
            styleName="link"
            aria-label="go to our facebook page"
          >
            <FaFacebookF aria-hidden="true" />
            {/* <FbIcon width={18} height={18} /> */}
          </a>
        </li>
      )}
      {socialLinks["gplus-url"] && (
        <li styleName="social-icon">
          <a
            href={socialLinks["gplus-url"]}
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="go to our google plus page"
          >
            <FaGooglePlusG aria-hidden="true" />
            {/* <GplusIcon width={18} height={18} /> */}
          </a>
        </li>
      )}
      {socialLinks["twitter-url"] && (
        <li styleName="social-icon">
          <a
            href={
              !hindi_domain
                ? socialLinks["twitter-url"]
                : publisher["hiTwitter"]
            }
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="go to our twitter page"
          >
            <FaXTwitter aria-hidden="true" />
            {/* <TwitterIcon width={18} height={18} /> */}
          </a>
        </li>
      )}
      {socialLinks["linkedin-url"] && (
        <li styleName="social-icon">
          <a
            href={socialLinks["linkedin-url"]}
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="go to our linkedin page"
          >
            <FaLinkedin aria-hidden="true" />
            {/* <LinkedinIcon width={18} height={18} /> */}
          </a>
        </li>
      )}
      {socialLinks["youtube-url"] && (
        <li styleName="social-icon">
          <a
            href={
              !hindi_domain
                ? socialLinks["youtube-url"]
                : // eslint-disable-next-line dot-notation
                  publisher["hiYoutube"]
            }
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="youtube"
          >
            <FaYoutube aria-hidden="true" />
            {/* <YoutubeIcon width={18} height={18} /> */}
          </a>
        </li>
      )}
      {socialLinks["instagram-url"] && (
        <li styleName="social-icon">
          <a
            href={
              !hindi_domain
                ? socialLinks["instagram-url"]
                : publisher["hiInstagram"]
            }
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="instagram"
          >
            <FaInstagram aria-hidden="true" />
            {/* <InstagramIcon width={18} height={18} /> */}
          </a>
        </li>
      )}
      {/* {hindi_domain &&(
        <li styleName="social-icon">
          <a
            href={
              "/hindi-whatsapp-optin"
            }
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={assetify(whatsapp)}
              alt="whatsapp optin"
              width="40px"
              height="40px"
            />
          </a>
        </li>
      )} */}
      {showRss && (
        <li styleName="social-icon">
          <a
            href={
              !hindi_domain
                ? "https://www.newslaundry.com/stories.rss?time-period=last-7-days&exclude-section-ids=15848,15817"
                : "https://www.newslaundry.com/stories.rss?section=newslaundry-hindi&time-period=last-7-days&exclude-section-ids=15848"
            }
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Click to open Rss stories"
          >
            <FaRss aria-hidden="true" />
            {/* <img
              src="https://thumbor-stg.assettype.com/newslaundry/2021-07/9c05f502-83c5-4728-a092-d5f51a6a02be/rss_xxl.png"
              alt="rss"
              width="16px"
              height="16px"
            /> */}
          </a>
        </li>
      )}
      {showPodcast && (
        <li styleName="social-icon">
          <a
            href={publisher["applepodcastUrl"]}
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="apple podcast"
          >
            <SiApplepodcasts aria-hidden="true" />
            {/* <img
              src={assetify(podcastIcon)}
              alt="apple podcast"
              width="16px"
              height="16px"
            /> */}
          </a>
        </li>
      )}
      {showTelegram && (
        <li styleName="social-icon">
          <a
            href={
              !hindi_domain
                ? publisher["telegramUrl"]
                : publisher["hiTelegramUrl"]
            }
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="telegram"
          >
            <FaTelegram aria-hidden="true" />
            {/* <img
              src={assetify(telegramIcon)}
              alt="telegram"
              width="16px"
              height="16px"
            /> */}
          </a>
        </li>
      )}
      {showLinkedin && (
        <li styleName="social-icon">
          <a
            href={publisher["linkedinUrl"]}
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="linkedin"
          >
            <FaLinkedin aria-hidden="true" />
            {/* <img
              src={assetify(linkedinIcon)}
              alt="linkedin"
              width="16px"
              height="16px"
            /> */}
          </a>
        </li>
      )}
      {showSpotify && (
        <li styleName="social-icon">
          <a
            href={publisher["spotifyUrl"]}
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="spotify"
          >
            <FaSpotify aria-hidden="true" />
            {/* <img
              src={assetify(spotifyIcon)}
              alt="spotify"
              width="16px"
              height="16px"
            /> */}
          </a>
        </li>
      )}
      {showCastBox && (
        <li styleName="social-icon">
          <a
            href={publisher["castboxUrl"]}
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="castbox"
          >
            <SiCastbox aria-hidden="true" />
            {/* <img
              src={assetify(castBoxIcon)}
              alt="linkedin"
              width="16px"
              height="16px"
            /> */}
          </a>
        </li>
      )}
      {false && (
        <li styleName="social-icon">
          <a
            href={
              !hindi_domain ? publisher["discordUrl"] : publisher["hiDiscord"]
            }
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="discord"
          >
            <FaDiscord
              size={"20px"}
              // color={"white"}
              width="16px"
              height="16px"
            />
          </a>
        </li>
      )}
      {showNewsletter && (
        <li styleName="social-icon">
          <a
            href="/newsletter-signup"
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="newsletter"
          >
            <TbMailOpened aria-hidden="true" />
            {/* // <img
            //   src="https://img.icons8.com/material-outlined/96/ffffff/email-open.png"
            //   width="16px"
            //   height="16px"
            // /> */}
          </a>
        </li>
      )}
      {showWhatsapp && (
        <li styleName="social-icon">
          <a
            href="https://whatsapp.com/channel/0029Va61PEo0QeacOqU9vr2k"
            styleName="link"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="whatsapp"
          >
            <FaWhatsapp aria-hidden="true" />
            {/* // <img
            //   src="https://img.icons8.com/material-outlined/96/ffffff/email-open.png"
            //   width="16px"
            //   height="16px"
            // /> */}
          </a>
        </li>
      )}
    </ul>
  );
}

SocialIconsBase.propTypes = {
  socialLinks: PT.object,
};

function mapStateToProps(state) {
  return {
    publisher: get(state, ["qt", "config", "publisher", "publisher"], []),
    hindi_domain: get(state, ["qt", "hindi_domain"], null),
  };
}

export const SocialIcons = connect(mapStateToProps)(SocialIconsBase);
