/* eslint-disable no-undef */
import wretch from "wretch";
import * as CryptoJS from "crypto-js";
import IntegrationData from "../../../../config/integrations";
import axios from "axios";
// import get from "lodash/get";
// import { INTEGRATION_TOKEN } from "./actions";

// Sign-In User
// export function login(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url("/api/auth/v1/login")
//     .post(body)
//     .unauthorized(() => Promise.reject(new Error("Invalid username or password")))
//     .json(res => Promise.resolve(res));
// }

export const storeCancelReason = ({
  emailAddress,
  userId,
  subscriberId,
  subscriptionId,
  reason,
  planId,
  planName,
  groupName,
  planType,
  durationLength,
  durationUnit,
  isGifted
}) =>
  axios.post("/cancelReason", {
    emailAddress,
    userId,
    subscriberId,
    subscriptionId,
    reason,
    planId,
    planName,
    groupName,
    planType,
    durationLength,
    durationUnit,
    isGifted
  });

export function login(body, callbackUrl, redirectUrl, authHost) {
  return wretch()
    .options({ credentials: "include" })
    .url(`${authHost}/sso-login`)
    .query({
      "callback-url": callbackUrl,
      "redirect-url": redirectUrl
    })
    .post(body)
    .json(res => {
      return res;
    })
    .catch(ex => Promise.reject(new Error(ex.message)));
}

// Sign-In User
// export function login(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url("/api/auth/v1/login")
//     .post(body)
//     .unauthorized(() => Promise.reject(new Error("Invalid username or password")))
//     .json(res => Promise.resolve(res));
// }

export function oneTapLogin(token) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/login/google-one-tap")
    .post({
      token: token,
      "redirect-url": window.location.href
    })
    .res();
}

// To check if the user is logged in or not.
// Returns true if the user is logged in (status === 200)
// Returns false if the user is NOT logged in (status !== 200)
export const checkLoggedInUser = async () => {
  try {
    const { status } = await axios.get(`/api/auth/v1/users/me`, {
      headers: {
        credentials: "same-origin"
      }
    });

    if (status === 200) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(`Error: ${error}`);
    return false;
  }
};

// Sign-Up User
// export function register(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url("/api/auth/v1/signup")
//     .post(body)
//     .json(res => Promise.resolve(res))
//     .catch(ex => Promise.reject(ex));
// }

export function register(body, callbackUrl, redirectUrl, authHost) {
  return wretch()
    .options({ credentials: "include" })
    .url(`${authHost}/sso-signup`)
    .query({
      "callback-url": callbackUrl,
      "redirect-url": redirectUrl
    })
    .post(body)
    .json(res => {
      return res;
    })
    .catch(ex => Promise.reject(ex));
}

// Send the email with OTP for verification
export function verifyEmail(email) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/users/send-otp")
    .post({ email: email })
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Send the email with OTP for verification for resetting the password
export function forgotPassword(email) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/member/forgot-password")
    .post({ email })
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

export function sessionHandler() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/kick")
    .post()
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

// Verify the email by validating OTP
export function verifyEmailOTP(otp, id, password = null) {
  const user = {
    "verification-status": "email"
  };

  // if (password) {
  //   member.password = password;
  // }

  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/api/auth/v1/users/update-with-otp`)
    .post({
      otp: `${otp}`,
      user: user
    })
    .res();
}

// Verify OTP and Reset Password
export function resetPasswordByOtp(otp, email, newPassword) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/api/auth/v1/users/reset-password`)
    .post({
      email: `${email}`,
      otp: `${otp}`,
      "new-password": `${newPassword}`
    })
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Returns the current User
export function getUser() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/v1/members/me")
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function getAuthors() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/v1/authors?limit=3000")
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export async function getStoryById(storyId) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/api/v1/stories/${storyId}`)
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function getSessions() {
  return wretch()
    .url("/api/auth/v1/sessions")
    .get()
    .json(res => {
      return Promise.resolve(res);
    })
    .catch(ex => Promise.reject(ex));
}

// Reset the password
export function resetPassword(token, password, repeatPassword) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/member/password")
    .post({
      token,
      password,
      "repeat-password": repeatPassword
    })
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Logout the User
export function logout() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/api/auth/v1/logout")
    .get()
    .res(() => Promise.resolve())
    .catch(ex => Promise.reject(ex));
}

export function getFirebaseUser() {
  return wretch("/user/get/")
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// Acast podcast api's
export function getAcastShows() {
  // return wretch("/acast-rest/shows")
  //   .get()
  //   .json(res => Promise.resolve(res))
  //   .catch(ex => {
  //     console.log({ex});
  //     Promise.reject(ex)
  //     });

  return axios
    .get("/acast-rest/shows", {
      headers: {
        "User-Agent": "NewslaundryWebsiteFrontend/1.0.1"
      }
    })
    .then(response => {
      const data = response.data;
      // console.log(data);
      return data;
    })
    .catch(error => {
      console.error({ ex: error });
      return Promise.reject(error);
    });
}

export function getAcastShow(showId) {
  // return wretch(`/acast-rest/shows/${showId}`)
  //   .get()
  //   .json(res => Promise.resolve(res))
  //   .catch(ex => Promise.reject(ex));

  return axios
    .get("/acast-rest/shows", {
      headers: {
        "User-Agent": "NewslaundryWebsiteFrontend/1.0.1"
      }
    })
    .then(response => Promise.resolve(response.data))
    .catch(error => {
      console.error({ ex: error });
      return Promise.reject(error);
    });
}

export async function getAcastEpisodes(id, page) {
  const response = await wretch(
    `/acast-rest/shows/${id}/episodes?page=${page}&perPage=10`,
    {
      headers: {
        "User-Agent": "NewslaundryWebsiteFrontend/1.0.1"
      }
    }
  ).get();

  const res = await response.res();
  let nextPage = res.headers.get("NEXT_PAGE");
  nextPage = nextPage === "null" ? null : nextPage;
  const body = await response.json();
  return { body, nextPage };
}
// const response = await axios.get(`/acast-rest/shows/${id}/episodes?page=${page}&perPage=10`)

export function getAcastEpisode(showId, episodeId) {
  // return wretch(`/acast-rest/shows/${showId}/episodes/${episodeId}`)
  //   .get()
  //   .json(res => Promise.resolve(res))
  //   .catch(ex => Promise.reject(ex));

  return axios
    .get(`/acast-rest/shows/${showId}/episodes/${episodeId}`, {
      headers: {
        "User-Agent": "NewslaundryWebsiteFrontend/1.0.1"
      }
    })
    .then(response => Promise.resolve(response.data))
    .catch(error => {
      console.error({ ex: error });
      return Promise.reject(error);
    });
}

export function getStudentSubscriptionPaymentLink(request) {
  return wretch("/payment-link")
    .headers({
      "Content-Type": "application/json"
    })
    .post(request)
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function updateLogSubscription(body) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/log/subscriptions")
    .patch(body)
    .res(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function getGiftStoryLink(body) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/gift-story/link")
    .post(body)
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function getGiftStoryCount(body) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/gift-story/count")
    .post(body)
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function sendGiftStoryEmails(body) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/gift-story/email")
    .post(body)
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function verifyGiftStoryLink(body) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url("/gift-story/verify")
    .post(body)
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function getPodcastToken(
  memberId,
  subscriptionName,
  expiryDate,
  showId
) {
  // return wretch()
  //   .options({ credentials: "same-origin" })
  //   .url(
  //     `/podcast-rss/getToken/${memberId}/${subscriptionName}/${expiryDate}/${showId}`
  //   )
  //   .get()
  //   .json(res => Promise.resolve(res));

  const url = `/podcast-rss/getToken/${memberId}/${subscriptionName}/${expiryDate}/${showId}`;

  return axios
    .get(url)
    .then(response => response.data)
    .catch(error => {
      console.error({ ex: error });
      throw error;
    });
}

export function getDiscordIdByEmail(email) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/getDiscordId/${email}`)
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

export function linkDiscordAccount(body) {
  const strObj = JSON.stringify(body);
  const enc = CryptoJS.AES.encrypt(strObj, "hackmeNowLOL12345");
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/discord/link`)
    .headers({ "Content-Type": "application/json" })
    .post({ enc: `${enc}` })
    .json(res => Promise.resolve(res));
}

// export function studentSponsorEmail(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url(`/sendgrid`)
//     .headers({ "Content-Type": "application/json" })
//     .post(body)
//     .json(res => Promise.resolve(res));
// }

// export function subscriptionGifterEmail(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url(`/sendgrid`)
//     .headers({ "Content-Type": "application/json" })
//     .post(body)
//     .json(res => Promise.resolve(res));
// }

// export function subscriptionsGifteeEmail(body) {
//   return wretch()
//     .options({ credentials: "same-origin" })
//     .url(`/sendgrid`)
//     .headers({ "Content-Type": "application/json" })
//     .post(body)
//     .json(res => Promise.resolve(res));
// }

export function getShopifyOrders() {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/shopify/orders`)
    .headers({ "Content-Type": "application/json" })
    .get()
    .json(res => Promise.resolve(res));
}

export function searchV2(query, start) {
  return wretch()
    .options({ credentials: "same-origin" })
    .url(`/search-v2/${unescape(query)}/${start}`)
    .headers({ "Content-Type": "application/" })
    .get()
    .json(res => Promise.resolve(res))
    .catch(ex => Promise.reject(ex));
}

// bearer token for CM APIs
export async function getIntegrationToken() {
  const integrationId = IntegrationData().isAccesstypeStaging
    ? IntegrationData().stagingAccessTypeBkIntegrationId
    : IntegrationData().accessTypeBkIntegrationId;
  const response = await fetch(
    `/api/auth/v1/access-token/integrations/${integrationId}`
  );
  return response.headers.get("x-integration-token");
  // return null
}

export const fetchUserPodcastHistory = async token => {
  try {
    const { data } = await axios.get("/cm-backend/podcast-history", {
      headers: {
        "User-Agent": "NewslaundryWebsiteFrontend/1.0.1",
        "x-integration-token": token
      }
    });

    return { podcastHistory: data.podcastHistory };
  } catch (error) {
    console.error(error);
    throw new Error("Something went wrong", error.message);
  }
};

export const createUserPodcastHistory = async (
  episodeId,
  durationPlayed,
  isDownloaded,
  token
) => {
  try {
    const { data } = await axios.post(
      "/cm-backend/podcast-history",
      {
        episodeId,
        durationPlayed,
        isDownloaded
      },
      {
        headers: {
          "User-Agent": "NewslaundryWebsiteFrontend/1.0.1",
          "x-integration-token": token
        }
      }
    );

    return { createdPodcast: data.createdPodcast };
  } catch (error) {
    console.error(error);
    return { createdPodcast: null };
  }
};
