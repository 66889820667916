/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import React from "react";
import get from "lodash/get";
import { connect } from "react-redux";
import { Link } from "@quintype/components";
import { parseUrl } from "query-string";
import PropTypes from "prop-types";
import { Logo } from "../../molecules/publisher-logo";
import { User } from "../user";
import { NavigationLinks } from "../navigation-link";
import { SubscriptionStatusBtn } from "../../molecules/subscription-status-btn";
import { IS_OPEN_LOGIN_FORM } from "../../helper/actions";
import "./navigation.m.css";
import { getGlobalPlayerRef } from "../../podcast-player/utils";
import SearchIcon from "../../atoms/icons/search-icon";
import debounce from "lodash.debounce";
import { isMobileScreen } from "../../helper/hooks";

import { FiSettings } from "react-icons/fi";
import { FaDownload } from "react-icons/fa6";

// import { Button } from "../../../ui/components";

class NavigationBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOffcanvasMenu: false,
      showPlayer: false,
      loadingUserQueue: false,
      playerState: {},
      scrollDirection: null,
      location: "",
    };
    this.toggleOffcanvasMenu = this.toggleOffcanvasMenu.bind(this);
    this.closeOffcanvasMenu = this.closeOffcanvasMenu.bind(this);
    this.handleNavigation = debounce(this.handleNavigation, 10, {
      leading: true,
    });
  }

  handleNavigation = () => {
    // Bounce scroll on iOS
    if (window.scrollY < 0) {
      return;
    }

    if (this.prev > window.scrollY) {
      this.navElement && this.navElement.classList.remove("nav-hide");
    } else if (this.prev < window.scrollY) {
      this.navElement && this.navElement.classList.add("nav-hide");
    }
    this.prev = window.scrollY;
  };

  componentDidMount() {
    this.setState({ location: global.location.host });
    const playerRef = getGlobalPlayerRef();
    this.removePlayerListener = playerRef.addPlayerListener((state) => {
      this.setState({
        showPlayer:
          (state.showPlayer && state.currentTrackId) || !!state.currentScreen,
        loadingUserQueue: state.loadingUserQueue,
        playerState: state,
      });
    });

    this.prev = window.scrollY;
    window.addEventListener("scroll", this.handleNavigation);
  }

  componentWillUnmount() {
    this.removePlayerListener();
    window.removeEventListener("scroll", this.handleNavigation);
  }

  async getSubscription() {
    const playerRef = getGlobalPlayerRef();
    if (!this.props.member) {
      // await this.props.manageLoginForm(true);
      playerRef.userClickedMicIcon = true;
      const params = parseUrl(this.props.currentPath);
      const callbackUrl = get(
        params,
        ["query", "callback-url"],
        global.location && global.location.origin
      );
      const redirectUrl = get(
        params,
        ["query", "redirect-url"],
        global && global.location && global.location.href
      );
      window.location = `${this.props.authHost}/authenticate?redirect-url=${redirectUrl}&callback-url=${callbackUrl}&auth-page=${this.props.authHost}/user-login`;
      playerRef.userClickedMicIcon = true;
    } else {
      // If track is playing show bottom player else show library screen
      if (this.state.playerState.currentTrackId) {
        playerRef.togglePlayer();
      } else {
        playerRef.setCurrentScreen("library");
      }
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(newProps) {
    !newProps.showOffcanvasMenu && this.closeOffcanvasMenu();
  }

  toggleOffcanvasMenu() {
    this.setState((prevState) => {
      return {
        showOffcanvasMenu: !prevState.showOffcanvasMenu,
      };
    });
  }

  closeOffcanvasMenu() {
    this.setState({
      showOffcanvasMenu: false,
    });
    const playerRef = getGlobalPlayerRef();
    playerRef.setShowMenu(false);
  }

  pushToDataLayer(event, title) {
    const obj = {
      event: event,
      subscription_category: title,
      usertype: this.props.member ? "logged in" : "logged out",
      subscribertype: this.props.fetchSubscription
        ? this.props.fetchSubscription.isActive
          ? "subscriber"
          : "nonsubscriber"
        : "nonsubscriber",
      userID: this.props.member ? this.props.member.id : "null",
      subscriptionID: this.props.fetchSubscription
        ? this.props.fetchSubscription.items.find((sub) => sub.active === true)
            .id
        : "null",
    };

    window.dataLayer.push(obj);
  }

  render() {
    return (
      <nav
        styleName="navigation"
        className="navigation"
        ref={(navElement) => (this.navElement = navElement)}
      >
        <div styleName="navigation-top">
          <div className="container" styleName="navigation-top-container">
            <div styleName="navigation-hamburger-button">
              {/*  <HamburgerMenu onClick={this.toggleOffcanvasMenu} /> */}
            </div>
            <div styleName="navigation-logo">
              <Link aria-label="Click to go to home page" href="/">
                <Logo />
              </Link>
            </div>
            <div styleName="navigation-icons">
              {/* <div styleName="social-share-icons">
                 <SocialIcons socialLinks={this.props.socialLinks} /> 
              </div> */}
              <div styleName="podcast-subscription-wrapper">
                <Link
                  href="/newsletter-signup?ref=nav"
                  styleName="newsletter-icon-link"
                >
                  <button
                    styleName="podcast-icon"
                    onClick={() =>
                      this.pushToDataLayer(
                        "nl_top_navigation",
                        "newsletter_subscription"
                      )
                    }
                    aria-label="newsletters"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      width={24}
                      height={24}
                      aria-hidden="true"
                      // className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 9v.906a2.25 2.25 0 0 1-1.183 1.981l-6.478 3.488M2.25 9v.906a2.25 2.25 0 0 0 1.183 1.981l6.478 3.488m8.839 2.51-4.66-2.51m0 0-1.023-.55a2.25 2.25 0 0 0-2.134 0l-1.022.55m0 0-4.661 2.51m16.5 1.615a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V8.844a2.25 2.25 0 0 1 1.183-1.981l7.5-4.039a2.25 2.25 0 0 1 2.134 0l7.5 4.039a2.25 2.25 0 0 1 1.183 1.98V19.5Z"
                      />
                    </svg>
                    {/* <img
                      src="https://img.icons8.com/material-outlined/96/ffffff/email-open.png"
                      width="33"
                      height="25"
                    /> */}
                  </button>
                </Link>
                <GetAppNudge />
                <SearchIconButton />
                <SettingsPageLink />
                {/* &nbsp; &nbsp; &nbsp; */}
                <div styleName="navigation-buttons">
                  <SubscriptionStatusBtn />
                </div>
              </div>
              <div styleName="user-icons">
                {/* <Search /> */}
                <User />
              </div>
            </div>
          </div>
        </div>
        {/* <div
          styleName="navigation-bottom"
          style={{
            display:
              (this.props.storyTemplate === "live-qa" ||
                this.props.currentPath.includes("/subscription") ||
                this.props.currentPath.includes("/gift") ||
                this.props.currentPath.includes("/campaign/checkout")) &&
              "none"
          }}
        >
          <div
            className="container"
            style={{ display: "flex", alignItems: "center" }}
          >
            <li
              styleName="item"
              className="desktop-mini-logo"
              style={{ padding: 0, alignSelf: "flex-end" }}
            >
              <Link href={"/"} style={{ lineHeight: 0 }}>
                <img
                  style={{ height: "3rem" }}
                  src="https://images.assettype.com/newslaundry/2020-10/fe2dc714-ad63-43b8-a43f-999fd1f9c7bc/qiMQgIos_400x400.png"
                  alt="logo"
                ></img>
              </Link>
            </li>
            <NavigationLinks
              links={this.props.menu}
              showOffcanvasMenu={this.state.showOffcanvasMenu}
              closeMenu={this.closeOffcanvasMenu}
              domainSlug={this.props.domainSlug}
            />
          </div>
        </div> */}
        <NavigationLinks
          links={this.props.menu}
          showOffcanvasMenu={this.state.showOffcanvasMenu}
          closeMenu={this.closeOffcanvasMenu}
          domainSlug={this.props.domainSlug}
        />
      </nav>
    );
  }
}

NavigationBase.propTypes = {
  socialLinks: PropTypes.object,
  menu: PropTypes.array,
  member: PropTypes.object,
  manageLoginForm: PropTypes.func,
  memberLoading: PropTypes.bool,
  authHost: PropTypes.string,
  currentPath: PropTypes.string,
  domainSlug: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
  manageLoginForm: function (payload) {
    dispatch({
      type: IS_OPEN_LOGIN_FORM,
      payload: payload,
    });
  },
});

function mapStateToProps(state) {
  const menu =
    get(state, ["qt", "data", "navigationMenu", "headerLinks"], []) ||
    get(state, ["qt", "data", "navigationMenu", "default"], []);
  return {
    title: "Newslaundry",
    menu: menu,
    socialLinks: get(state, ["qt", "config", "social-links"], []),
    showOffcanvasMenu: state.offcanvasMenu,
    member: get(state, ["member"], null),
    memberLoading: state.memberLoading,
    currentPath: get(state, ["qt", "currentPath"], ""),
    storyTemplate: get(state, ["qt", "data", "story", "story-template"], null),
    authHost: get(
      state,
      ["qt", "config", "publisher", "publisher", "auth_host"],
      null
    ),
    domainSlug: get(state, ["qt", "domainSlug"], null),
    subscription: get(state, ["fetchSubscription"], {}),
  };
}

const SettingsPageLink = () => {
  return (
    <div styleName="settings-page-link-wrapper">
      <Link href="/settings" aria-label="settings page link">
        <FiSettings aria-hidden="true" />
      </Link>
    </div>
  );
};

const GetAppNudge = () => {
  const isMobScreen = isMobileScreen();

  if (!isMobScreen) {
    return null;
  }

  return (
    <Link href="/download-app?ref=nav-cta" styleName="redirect-link">
      <FaDownload style={{ width: "0.8rem", height: "0.8rem" }} />
      Get app
    </Link>
  );
};

function SearchIconButton() {
  const isMobScreen = isMobileScreen();

  if (isMobScreen) {
    return null;
  }

  return (
    <div styleName="search-stories-component">
      <Link href={"/search"} aria-label="Go to search page">
        <SearchIcon className="search-stories-component-icon" height={20} />
      </Link>
    </div>
  );
}

export const NavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavigationBase);
