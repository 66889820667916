/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
import { getIntegrationToken } from "./components/helper/api";
import axios from "axios";

// const shopifyUrl = "https://backend.cm.newslaundry.com/api/shopify/orders";
const commentUrl = "https://backend.cm.newslaundry.com/api/comment-section";
const reportUrl = "https://backend.cm.newslaundry.com/api/report-comments";
// const userUrl = "https://backend.cm.newslaundry.com/api/users";
// const storyUrl = "https://backend.cm.newslaundry.com/api/story";
// const giftUrl = "https://backend.cm.newslaundry.com/api/gift-subscription";

const customUserAgent = "NewslaundryWebsiteFrontend/1.0.1";

const axiosInstance = async () => {
  try {
    const token = await getIntegrationToken();

    const instance = axios.create({
      baseURL: "/cm-backend",
      headers: {
        "Content-Type": "application/json",
        "User-Agent": customUserAgent,
        "x-integration-token": token
      }
    });

    return instance;
  } catch (error) {
    console.error({ axiosInstanceCartoonMangoError: error });
    return null;
  }
};

/*
  Create user in MySql cartoon mango database from bridgkeeper member
*/
async function createCMUser(member, activeSubscription) {
  try {
    const instance = await axiosInstance();

    if (!instance) {
      return {
        message: "User must be logged in to continue"
      };
    }

    const { data } = await instance.post("/user", {
      member,
      activeSubscription
    });

    return data;

    // const token = await Promise.resolve(getIntegrationToken());
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   avatar_url: member["avatar-url"],
    //   user_name: member["first-name"] || member.name,
    //   first_name: member["first-name"],
    //   "last-name": member["last-name"],
    //   subscription_details: activeSubscription,
    //   email: member.email
    // });

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow"
    // };

    // const res = await fetch(userUrl, requestOptions);
    // return res.json();
    // throw new Error("Temp error");
  } catch (err) {
    console.log(err);
  }
}

async function createShopifyOrder(payload) {
  try {
    const instance = await axiosInstance();

    if (!instance) {
      return {
        message: "User must be logged in to continue"
      };
    }

    const { data } = await instance.post("/shopify-order", payload);

    return data;
    // const token = await Promise.resolve(getIntegrationToken());
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify(payload);

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow"
    // };

    // const res = await fetch(shopifyUrl, requestOptions);
    // return res.json();
  } catch (err) {
    console.log(err);
  }
}

async function createGiftSubscription(payload) {
  try {
    const instance = await axiosInstance();

    const { data } = await instance.post(".gift-subscription", payload);

    if (!instance) {
      return {
        message: "User must be logged in to continue"
      };
    }

    return data;
    // const token = await Promise.resolve(getIntegrationToken());
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify(payload);

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow"
    // };

    // const res = await fetch(giftUrl, requestOptions);
    // return res.json();
  } catch (err) {
    console.log(err);
  }
}

async function fetchComments({ pageParam = 0, queryKey }) {
  try {
    const limit = 5;
    const token = await Promise.resolve(getIntegrationToken());
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    var requestOptions = {
      method: "GET",
      headers: myHeaders
    };

    const res = await fetch(
      `${commentUrl}/${queryKey[1].storyID}?skip=${pageParam}&limit=${limit}&sort=desc`,
      token ? requestOptions : null
    );
    return res.json();
  } catch (err) {
    console.log(err);
  }
}

async function fetchReplies({ pageParam = 1, queryKey }) {
  try {
    const token = await Promise.resolve(getIntegrationToken());
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    const res = await fetch(
      `${commentUrl}/reply/${queryKey[1].commentID}/?items=5&page=${pageParam}&sort=asc`,
      token ? requestOptions : null
    );
    return res.json();
  } catch (e) {
    console.log(e);
  }
}

async function postComment({ inputText, storyID, parentID }) {
  try {
    const token = await Promise.resolve(getIntegrationToken());
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      comments: inputText,
      parent_id: parentID,
      story_id: storyID
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    const res = await fetch(commentUrl, requestOptions);
    return res.json();
  } catch (e) {
    console.log(e);
  }
}

async function editComment({ inputText, id }) {
  try {
    const token = await Promise.resolve(getIntegrationToken());
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      comments: inputText,
      image: null
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };

    const res = await fetch(`${commentUrl}/${id}`, requestOptions);
    return res.json();
  } catch (e) {
    console.log(error);
  }
}

async function deleteComment({ id }) {
  try {
    const token = await Promise.resolve(getIntegrationToken());
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow"
    };

    await fetch(`${commentUrl}/deleted/${id}`, requestOptions);
  } catch (e) {
    console.log(e);
  }
}

async function updateLike({ id }) {
  try {
    const token = await Promise.resolve(getIntegrationToken());
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions = {
      method: "PUT",
      headers: myHeaders
    };

    await fetch(`${commentUrl}/liked/${id}`, requestOptions);
  } catch (error) {
    throw new Error(error);
  }
}

async function updateFeature(id, isFeatured) {
  try {
    const token = await Promise.resolve(getIntegrationToken());
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      is_featured: !isFeatured
    });

    var requestOptions = {
      method: "put",
      headers: myHeaders,
      redirect: "follow",
      body: raw
    };

    await fetch(`${commentUrl}/featured/${id}`, requestOptions);
  } catch (error) {
    throw new Error(error);
  }
}

async function reportComment(commentID, storyID, comments, setComments) {
  const token = await Promise.resolve(getIntegrationToken());
  var myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    comment_id: commentID,
    report_type: "1",
    is_reported: true
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  await fetch(reportUrl, requestOptions)
    .then(response => response.text())
    .then(result => {
      fetchComments(storyID, setComments);
    })
    .catch(error => console.log("error", error));
}

async function deleteBookmark(storyId) {
  try {
    const instance = await axiosInstance();

    if (!instance) {
      return {
        message: "User must be logged in to continue"
      };
    }

    const { data } = await instance.delete(`/bookmark/${storyId}`);

    return data;
    // const token = await Promise.resolve(getIntegrationToken());
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    // var requestOptions = {
    //   method: "DELETE",
    //   headers: myHeaders
    // };

    // await fetch(`${storyUrl}/bookmark/${storyId}`, requestOptions);
  } catch (error) {
    throw new Error(error);
  }
}

async function createBookmark(id, storyDetail, isSaved) {
  try {
    const instance = await axiosInstance();

    if (!instance) {
      return {
        message: "User must be logged in to continue"
      };
    }

    const { data } = await instance.post("/bookmark", {
      storyId: id,
      storyDetail
    });

    return data;
    // const token = await Promise.resolve(getIntegrationToken());
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //   story_id: id,
    //   story_detail: storyDetail
    // });

    // var requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw
    // };

    // await fetch(`${storyUrl}/bookmark`, requestOptions);
  } catch (error) {
    throw new Error(error);
  }
}

async function fetchBookmark() {
  try {
    const instance = await axiosInstance();

    if (!instance) {
      return {
        message: "User must be logged in to continue"
      };
    }

    const { data } = await instance.get("/bookmark");

    return data;
    // const token = await Promise.resolve(getIntegrationToken());
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    // var requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    //   redirect: "follow"
    // };

    // const res = await fetch(`${storyUrl}/bookmark`, requestOptions);
    // return res.json();
  } catch (e) {
    console.log(e);
  }
}

async function fetchBookmarkById(storyId) {
  try {
    const instance = await axiosInstance();

    if (!instance) {
      return {
        message: "User must be logged in to continue"
      };
    }

    const { data } = await instance.get(`/bookmark/${storyId}`);

    return data;

    // const token = await Promise.resolve(getIntegrationToken());
    // var myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${token}`);
    // myHeaders.append("Content-Type", "application/json");

    // var requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    //   redirect: "follow"
    // };

    // const res = await fetch(`${storyUrl}/bookmark/${storyId}`, requestOptions);
    // return res.json();
  } catch (e) {
    console.log(e);
  }
}

export {
  createCMUser,
  createShopifyOrder,
  createGiftSubscription,
  fetchComments,
  fetchReplies,
  postComment,
  editComment,
  deleteComment,
  updateLike,
  updateFeature,
  // getTimestamp,
  reportComment,
  createBookmark,
  deleteBookmark,
  fetchBookmark,
  fetchBookmarkById
};
