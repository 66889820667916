import React, { useEffect, useState } from "react";

import MobileLogo from "../../atoms/icons/mobile-logo";

import { useSettingsStore } from "../../../context/useSettingsState";
import "./publihser-logo.m.css";
import {
  COMPRESS_PARAMS_SMALL,
  DARK_ENGLISH_TRANSPARENT_LOGO_URL,
  DARK_HINDI_TRANSPARENT_LOGO_URL,
  ENGLISH_TRANSPARENT_LOGO_URL,
  HINDI_TRANSPARENT_LOGO_URL,
} from "../../../image-constants";

export function Logo() {
  const [isHindi, setIsHindi] = useState(false);

  const themeState = useSettingsStore((state) => state.theme);
  const [calculatedTheme, setCalculatedTheme] = useState(themeState);

  React.useEffect(() => {
    setIsHindi(global.location.host.includes("hindi."));
  }, []);

  useEffect(() => {
    if (themeState === "system") {
      const isDarkTheme = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setCalculatedTheme(isDarkTheme ? "dark" : "light");
    } else {
      setCalculatedTheme(themeState);
    }
  }, [themeState]);

  return (
    <React.Fragment>
      <div className="desktop-only ">
        {isHindi ? (
          calculatedTheme === "dark" ? (
            <img
              // src={assetify(HindiBlackLogo)}
              src={`${DARK_HINDI_TRANSPARENT_LOGO_URL}${COMPRESS_PARAMS_SMALL}`}
              loading="eager"
              alt="Newslaundry"
              styleName="logo-img hindi-logo"
              // width="160px"
              // height="20px"
            />
          ) : (
            <img
              // src={assetify(HindiWhiteLogo)}
              src={`${HINDI_TRANSPARENT_LOGO_URL}${COMPRESS_PARAMS_SMALL}`}
              loading="eager"
              alt="Newslaundry"
              styleName="logo-img hindi-logo"
              // width="160px"
              // height="20px"
            />
          )
        ) : calculatedTheme === "dark" ? (
          <img
            // src={assetify(EnglishBlackLogo)}
            src={`${DARK_ENGLISH_TRANSPARENT_LOGO_URL}${COMPRESS_PARAMS_SMALL}`}
            loading="eager"
            alt="Newslaundry"
            styleName="logo-img"
            // width="160px"
            // height="20px"
          />
        ) : (
          <img
            // src={assetify(EnglishWhiteLogo)}
            src={`${ENGLISH_TRANSPARENT_LOGO_URL}${COMPRESS_PARAMS_SMALL}`}
            loading="eager"
            alt="Newslaundry"
            styleName="logo-img"
            // width="160px"
            // height="20px"
          />
        )}
      </div>
      <div className="mobile-only" styleName="logo-margin">
        <MobileLogo />
      </div>
    </React.Fragment>
  );
}
